import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { version } from "../package.json"
import { useAuthAccountStore, useAuthCompanyStore, useLocalStore, useLoggedUserStore, useProductStore } from "@sicarx/sxw-api"

export const initSentry = () => {

    //const isProd = !(import.meta.env?.VITE_TESTING === 'TESTING');


    queueMicrotask(() => {

        Sentry.init({
            enabled: false,
            release: `SXW@${version}`,
            dsn: "https://c49dc78acc404901a9ebb60090ecf698@sentry.sicarx.com/6" /* import.meta.env.VITE_SENTRY_DSN, */ /* "https://c49dc78acc404901a9ebb60090ecf698@sentry.sicarx.com/6" */,
            environment: 'development',
            tracesSampleRate: 1,//0.2,
            sampleRate: 1,
            //autoSessionTracking: false,
            integrations: [new BrowserTracing()],
            initialScope(scope) {

                setInfo(scope)

                return scope
            },
            beforeSend(event, hint) {


                if (event.exception) {

                    const info = setInfo(Sentry)

                    if (info) return event
                }

                return null
            }
        })

    })

}

const setInfo = (setter: Pick<typeof Sentry | Sentry.Scope, 'setUser' | 'setTags' | 'setExtras'>) => {

    const account = useAuthAccountStore.getState().authAccount

    if (!account.logged) return false

    const company = useAuthCompanyStore.getState().authCompany
    const branch = useLocalStore.getState().branchOffice
    const warehouseId = useLocalStore.getState().getLocal()?.warehouse?.uuid
    const priceListId = useProductStore.getState().prices.priceList?.uuid
    const loggedUser = useLoggedUserStore.getState().loggedUser

    const info = {
        user: {
            id: loggedUser?.uuid,
            email: account.email,
            username: `${account.firstName} ${account.lastName}`,
        },
        tags: {
            'content.id': company?.contentId,
            'branch.id': branch?.id,
            'company.alias': company?.alias,
            'branch.alias': branch?.alias,
        },
        extra: {
            'Country': branch.countryName,
            'Admin': company.isAdmin,
            'User type': loggedUser?.userTypeName,
            'PriceList ID': priceListId,
            'Warehouse ID': warehouseId
        }
    }

    setter.setUser(info.user)
    setter.setTags(info.tags)
    setter.setExtras(info.extra)

    return true
}