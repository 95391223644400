/* import { useMediaQuery, useTheme } from "@mui/material"; */
import { Box } from "@mui/material";
import { RootSubscriptionHandlerState, secureSessionStorage, useSubscriptionHandlerStore, /* usePermission */ } from "@sicarx/sxw-api";
import {
    CompleteTransaction, PagoConTarjeta,/* , SubscriptionDialog */
    SicarDialog
} from "@sicarx/sxw-components";
import { TSUBSCRIPTION/* , jwt_decode */ } from "@sicarx/sxw-core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
/* import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'; */
type ISubscribe = {
    planId: number
    amount: number
    isSubscribe: boolean
    transactionId: string
    paymentOption: string
    nextChargeDate: string
}

export const PaymentComponent = (() => {
    const navigator = useNavigate();
    /* const { checkHasPermission } = usePermission();
    const matches = useMediaQuery('(min-width:360px)');
    const theme = useTheme(); */
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        /* if (!checkHasPermission(StatisticsActions.READ_GENERALL_STATISTICS)) { */
        /* if (isMounted) { */
        if (!(location.search.split("=").length >= 2)) {
            navigator("/dashboard", { replace: true })
        }
        /* } */
        /* console.log(location.search.split("="));
        console.log(location.search.split("&")) */
      /*   console.log("tmpUuid=b4086a6a-d078-46d9-b977-c3c692774e6a")
        console.log("tmpBranchId=804")
        console.log("tmpAuth=eyJhbGciOiJIUzUxMiIsInQiOjF9.eyJleHAiOjE2OTUxMDMzNTMsImkiOiJxbHArTjZqT1d5REs1RFlVTTBCdmV3PT0iLCJpYXQiOjE2OTUwNzQ1NTMsImp0aSI6Iml2YW5Ac2ljYXIubXgifQ.xkXVi0E-wxW0aH-19kwaT214WHA7VtAlga_1y57okTI-JAe6DlfjXoRO0SK77sNHQszRWfAvzd3ffqIC6hm_rQ")
        console.log("tmpLocale=es-MX")
        console.log("tmpTimezone=America/Mexico_City") */


        if (location.search.split("=").length >= 2) {
            /* console.log("tmpAuth", location?.search?.split("&")[2]?.split("=")[1])
            console.log("tmpLocale", location?.search?.split("&")[1]?.split("=")[1])
            console.log("tmpTimezone", location?.search?.split("&")[0]?.split("=")[1]) */

            secureSessionStorage.setItem("tmpUuid", location?.search?.split("&")[4]?.split("=")[1])
            secureSessionStorage.setItem("tmpBranchId", location?.search?.split("&")[3]?.split("=")[1])
            secureSessionStorage.setItem("tmpAuth", location?.search?.split("&")[2]?.split("=")[1])
            secureSessionStorage.setItem("tmpLocale", location?.search?.split("&")[1]?.split("=")[1])
            secureSessionStorage.setItem("tmpTimezone", location?.search?.split("&")[0]?.split("=")[1])
        } else {
            secureSessionStorage.removeItem("tmpUuid")
            secureSessionStorage.removeItem("tmpBranchId")
            secureSessionStorage.removeItem("tmpAuth")
            secureSessionStorage.removeItem("tmpTimezone")
            secureSessionStorage.removeItem("tmpLocale")
        }

        /*}*/
        setIsMounted(true);
    }, []);

    return isMounted ? <Wrapper /> : null
})

const Wrapper = (() => {
    const getPlans = useSubscriptionHandlerStore((store: RootSubscriptionHandlerState) => store.getPlans);
    const [plansResponse, setPlansResponse] = useState<any>([]);
    const { t } = useTranslation();
    const [subscribe, setSubscribe] = useState<ISubscribe | null>({
        planId: 0,
        amount: 0,
        isSubscribe: false,
        transactionId: '',
        paymentOption: '',
        nextChargeDate: ''
    });
    const [tmpData, setTmpData] = useState<{ uuid: string | null | undefined, branchId: string | null | undefined, locale: string | null | undefined, timezone: string | null | undefined, jwt: string | null | undefined, }>(
        {
            uuid: secureSessionStorage?.getItem("tmpUuid"),
            branchId: secureSessionStorage?.getItem("tmpBranchId"),
            locale: secureSessionStorage?.getItem("tmpLocale"),
            timezone: secureSessionStorage?.getItem("tmpTimezone"),
            jwt: secureSessionStorage?.getItem("tmpAuth"),
        }
    );

    useEffect(() => {
        (async () => {
            /* console.log(await getPlans()) */
            setPlansResponse(await getPlans())
        })()
    }, [])



    return <>
        <SicarDialog
            open={true}
            title={t(TSUBSCRIPTION.CARD_PAYMENT, { ns: 'subscription' })}
            fullScreen
            PaperProps={{
                sx: {
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    transition: 'ease .5s',
                    backgroundColor: "background.default",
                    maxHeight: 'calc(100% )',
                }
            }}
        >
            {
                subscribe?.isSubscribe
                    ? <CompleteTransaction {...subscribe} branchId={Number(tmpData.branchId)} />
                    : <Box sx={{ padding: "40px" }}>
                        <PagoConTarjeta plans={plansResponse} buyNewBranch={false} setSubscribe={setSubscribe} fromMobile={tmpData} />
                    </Box>
            }
        </SicarDialog>
    </>
})