import { Button, ThemeProvider } from '@mui/material'
import { createThemeWithMode } from '@sicarx/sxw-core'
import { Suspense, lazy, useEffect, useMemo } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { DrawerM, SicarLoading } from '@sicarx/sxw-components';
import { IndexRoutes } from './routes'
import { useLocalStore } from '@sicarx/sxw-api';

const Feedback = lazy(() => import("@sicarx/sxw-components").then(({ Feedback }) => ({ default: Feedback })));
const UpdatePermissionsAlert = lazy(() => import("./components/UpdatePermissionsAlert").then(({ UpdatePermissionsAlert }) => ({ default: UpdatePermissionsAlert })));
const SubscriptionDialog = lazy(() => import("@sicarx/sxw-components").then(({ SubscriptionDialog }) => ({ default: SubscriptionDialog })));

function App() {
    let snackBarOffline: SnackbarKey;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isDarkMode = useLocalStore(state => state.darkMode.isDarksMode);
    const isDevelopment = import.meta.env.VITE_TESTING === 'TESTING';
    const mode = isDarkMode ? 'dark' : 'light';
    //Development
    //const theme = useMemo(() => createThemeWithMode(mode, localStorage?.getItem("mainColor") ? false : isDevelopment), [mode, localStorage?.getItem("mainColor") ? false : isDevelopment]);

    //Production
    const theme = useMemo(() => createThemeWithMode(mode), [mode]);

    useEffect(() => {
        //console.log("location", location)
        window.addEventListener("online", () => {
            enqueueSnackbar('Conectado', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
            closeSnackbar(snackBarOffline)
        });
        window.addEventListener("offline", () => {
            snackBarOffline = enqueueSnackbar('Desconectado', {
                variant: 'error',
                persist: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
        });

        return () => {
            window.removeEventListener("offline", () => {
                console.log("Estamos fuera de linea")
            });
            window.removeEventListener("online", () => {
                console.log("Estamos en linea")

            });
        };
    }, [])
    function method() {
        /* @ts-ignore */
        methodDoesNotExist()
    }
    return (<>
        <ThemeProvider theme={theme}>
            {location.pathname === "/subscription_mobile"
                ? <Suspense>
                    <IndexRoutes />
                </Suspense>
                :
                <>
                    <DrawerM />
                    <Suspense>
                        <SubscriptionDialog />
                    </Suspense>
                    <SicarLoading />
                    <Suspense>
                        <Feedback />
                    </Suspense>
                    <Suspense>
                        <IndexRoutes />
                    </Suspense>
                    <Suspense>
                        <UpdatePermissionsAlert />
                    </Suspense>

                   {/** <button onClick={() => method()}>Break the world</button> */}
                </>
            }

        </ThemeProvider >
    </>)
}

export default App;